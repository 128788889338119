<template>
  <div id="app" class="overflow-auto">
		<div id="loading" class="justify-content-center align-items-center" :class="{ 'd-flex': isLoading === true }">
      <div class="spinner-border text-primary"></div>
    </div>
    <notifications />
    <router-view />
  </div>
</template>

<script>
import Vuex from 'vuex'
import Notifications from '@/components/Notifications'
export default {
  computed: {
    ...Vuex.mapGetters('App', ['isLoading'])
  },
  components: {
    Notifications
  }
}
</script>

<style lang="scss" scoped>
  #loading {
    display: none;
    position: absolute;
    z-index: 9999; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;  
    background-color : rgba(255, 255, 255, 0.8);
  }
</style>
