export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    notifications (state) {
      return state.list
    }
  },
  mutations: {
    ADD (state, notif) { 
      state.list.push(notif) 
    },
    CLEAR (state, index) {
      state.list = state.list.filter((n) => n.index !== index)
    },
    CLEAR_ALL (state) { 
      state.list = [] 
    }
  },
  actions: {
    /*
    {
      type (optional => default info)
      title (optional)
      message (required)
      icon (optional => default fas fa-times)
      timeout (optional => default not closing automaticaly)
      onClick (optional => default case closes the notif)
    }
    */
    notify (store, notification) {
      let index = Date.now() + '_' + store.getters['notifications'].length
      store.commit('ADD', {
        index,
        ...notification
      })
      if (notification.timeout === undefined || notification.timeout > 0) {
        setTimeout(function () {
          store.commit('CLEAR', index)
        }, notification.timeout || 10000)
      }
    }
  }
}
