import api from './api'
export default {
  async login (username, password) {
    try {
      let response = await api.createRequest().post('/account/login', { username, password })
      if (response.data !== null) {
        return response.data
      }
    } catch (err) {
      console.warn(err)
    }
    return false
  }
}
