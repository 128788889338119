<template>  
  <div class="col-sm-6 col-xd-4 col-lg-3 mx-auto py-5">
    <img class="logo mx-auto mb-5" src="./../assets/img/logo.png" />
    <b-form @submit.prevent="login">
      <b-form-group label="Nom d'utilisateur">
        <b-form-input type="text" name="username" v-model="username" />
      </b-form-group>
      <b-form-group label="Mot de passe">
        <b-form-input type="password" name="password" v-model="password" />
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="rememberMe">Se rappeler de moi ?</b-form-checkbox>
      </b-form-group>
      <b-btn type="submit" variant="primary" class="w-100" :disabled="$v.$invalid === true">Connexion</b-btn>
    </b-form>
    <hr class="bg-light" />
    <router-link to="#" class="d-block">Mot de passe oublié ?</router-link>
  </div>
</template>

<script>
import Vuex from 'vuex'
import md5 from 'md5'
import { required } from 'vuelidate/lib/validators'
import account from '@/services/account'
export default {
  data () {
    return {
      username: '',
      password: '',
      rememberMe: false
    }
  },
  methods: {
    ...Vuex.mapActions('Notifications', ['notify']),
    async login () {
      if (this.$v.$invalid === false) {
        if (await account.login(this.username, md5(this.password)) === true) {
          if (this.rememberMe) {
            // TODO
          }
          this.$router.push({ name: 'home' })
        } else {
          this.notify({
            type: 'danger',
            title: 'Erreur',
            icon: 'fa-exclamation-triangle',
            message: 'Echec de l\'authentification !'
          })
        }
      }
    }
  },
  validations: {
    username: {
      required
    },
    password: {
      required
    }
  }
}
</script>

<style scoped lang="scss">
.logo {
  max-height: 10rem;
  max-width: 10rem;
  display: block;
}
</style>
