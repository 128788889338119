<template>  
  <div class="toasts">
    <div class="toast" role="alert" 
      v-for="notification in notifications" :key="notification.index"
      @click="exec(notification)"
    >
      <div class="toast-header" :class="notification.type || 'secondary'">
        <strong class="mr-auto">{{ notification.title }}</strong>
        <i class="fas ml-2" :class="notification.icon || 'fa-times'" />
      </div>
      <div class="toast-body">
        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
export default {
  computed: {
    ...Vuex.mapGetters('Notifications', ['notifications'])
  },
  methods: {
    exec (n) {
      if (n.onClick) {
        n.onClick()
      }
      this.$store.commit('Notifications/CLEAR', n.index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";
.toasts {
  position: absolute;
  top: .75rem;
  right: .75rem;
  max-width: 300px;
  z-index: 9998;
  &>.toast {
    opacity: 1;    
    &:hover {
      cursor: pointer;    
      &>.toast-body {
          color: color-yiq(darken($white, 7.5%));
          background-color: darken($white, 7.5%);
      }  
      &>.toast-header {
        &.primary {
          color: color-yiq(darken($primary, 7.5%));
          background-color: darken($primary, 7.5%);
        }
        &.secondary {
          color: color-yiq(darken($secondary, 7.5%));
          background-color: darken($secondary, 7.5%);
        }
        &.success {
          color: color-yiq(darken($success, 7.5%));
          background-color: darken($success, 7.5%);
        }
        &.danger {
          color: color-yiq(darken($danger, 7.5%));
          background-color: darken($danger, 7.5%);
        }
        &.warning {
          color: color-yiq(darken($warning, 7.5%));
          background-color: darken($warning, 7.5%);
        }
        &.info {
          color: color-yiq(darken($info, 7.5%));
          background-color: darken($info, 7.5%);
        }
        &.light {
          color: color-yiq(darken($light, 7.5%));
          background-color: darken($light, 7.5%);
        }
        &.dark {
          color: color-yiq(darken($dark, 7.5%));
          background-color: darken($dark, 7.5%);
        }
      }
    }
    &>.toast-header {
      &.primary {
        color: color-yiq($primary);
        background-color: $primary;
      }
      &.secondary {
        color: color-yiq($secondary);
        background-color: $secondary;
      }
      &.success {
        color: color-yiq($success);
        background-color: $success;
      }
      &.danger {
        color: color-yiq($danger);
        background-color: $danger;
      }
      &.warning {
        color: color-yiq($warning);
        background-color: $warning;
      }      
      &.info {
        color: color-yiq($info);
        background-color: $info;
      }
      &.light {
        color: color-yiq($light);
        background-color: $light;
      }
      &.dark {
        color: color-yiq($dark);
        background-color: $dark;       
      }
    }
    &>.toast-body {
        color: color-yiq($white);
        background-color: $white;
    }
  }
}
</style>