export default {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    isLoading (state) {
      return state.loading
    }
  },
  mutations: {
    CHANGE_LOADING_STATE (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setLoading (store, loading) {
      if (store.state.loading !== loading) {
        store.commit('CHANGE_LOADING_STATE', loading)
      }
    }
  }
}
