import axios from 'axios'
import store from './../store'
function getUrl () {
  if (process.env.NODE_ENV === 'production') {
    return `${location.protocol}//${location.host}`
  } else {
    return 'http://localhost:8081'
  }
}
function loading (state) {
  store.dispatch('App/setLoading', state)
}
export default {
  getUrl,
  createRequest () {
    const instance = axios.create({
      timeout: 300000, // 5 minutes
      baseURL: `${getUrl()}/api`,
      responseType: 'json'
    })
    instance.defaults.headers.post['Content-Type'] = 'application/json'
    instance.interceptors.request.use((config) => {
      loading(true)
      return config
    })
    instance.interceptors.response.use((response) => {
      loading(false)
      return response
    }, (error) => {
      console.log('response error', error)
      loading(false)
      return error
    })
    return instance
  }
}
